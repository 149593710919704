import { Controller } from 'stimulus';
import { gsap } from 'gsap';

/**
 * @extends {Controller}
 * @property {boolean} hasPanelTarget
 * @property {HTMLElement} panelTarget
 * @property {boolean} hasModalTarget
 * @property {HTMLElement} modalTarget
 * @property {boolean} hasDialogTarget
 * @property {HTMLElement} dialogTarget
 * @property {boolean} hasSpinnerTarget
 * @property {HTMLElement} spinnerTarget
 */
export default class extends Controller {
  /** @type {string[]} */
  static targets = [
    'modal',
    'dialog',
    'panel',
    'spinner'
  ]

  connect() {
    this.boundDialogClick = this.dialogClick.bind(this);
    this.boundEscapePress = this.escapePress.bind(this);
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundEscapePress);
    document.removeEventListener('click', this.boundDialogClick);
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  handleTurboStream(event) {
    const { action, target } = event.detail.newStream;

    if (target === 'trade_modal_panel' && action === 'replace') {
      event.preventDefault();
      this.handlePanelAdditionOrReplacement(event.detail.newStream);
    }
  }

  handlePanelAdditionOrReplacement(newStream) {
    const isMobile = window.matchMedia('(max-width: 640px)').matches;
    const templateContent = newStream.querySelector('template').content;
    const newTurboFrame = templateContent.querySelector('turbo-frame');
    gsap.to(this.spinnerTarget, {
      opacity: 0,
      duration: 0.3,
      ease: 'power1.out',
      onComplete: () => {
        this.spinnerTarget.classList.add('hidden');
      }
    })

    // Check if the close modal partial is passed, if it is close the modal
    if (newTurboFrame.dataset.modal === 'close') {
      this.closeModal()
      return
    }

    const currentPanel = this.element.querySelector('[data-modal-trade-target="panel"]');

    if (!currentPanel) {
      // Set initial state for new frame
      gsap.set(newTurboFrame, {
        opacity: 0,
        y: isMobile ? 16 : 0,
        scale: isMobile ? 1 : 0.95
      });

      // Add new content to DOM
      document.getElementById('trade_modal_panel').appendChild(newTurboFrame);

      gsap.to(newTurboFrame, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.3,
        ease: 'power1.out'
      });
    } else {
      // Animate out current panel
      gsap.to(currentPanel, {
        opacity: 0,
        y: isMobile ? 16 : 0,
        scale: isMobile ? 1 : 0.95,
        duration: 0.2,
        ease: 'power1.in',
        onComplete: () => {
          // Set initial state
          gsap.set(newTurboFrame, {
            opacity: 0,
            y: isMobile ? 16 : 0,
            scale: isMobile ? 1 : 0.95
          });

          // Replace with new content
          document.getElementById('trade_modal_panel').innerHTML = '';
          document.getElementById('trade_modal_panel').appendChild(newTurboFrame);

          gsap.to(newTurboFrame, {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.3,
            ease: 'power1.out'
          });
        }
      });
    }
  }

  /**
   * @param {MouseEvent} e
   */
  dialogClick = (e) => {
    if (this.hasPanelTarget && this.panelTarget.contains(e.target)) {
      return;
    }

    this.closeModal();
  };

  /**
   * @param {KeyboardEvent} e
   */
  escapePress = (e) => {
    if (e.key === 'Escape') {
      this.closeModal();
    }
  };

  /**
   * @param {MouseEvent} e
   * @param options {Object}
   */
  openModal(e, options = {}) {
    this.lastActiveElement = document.activeElement;

    // Don't allow the background to scroll when modal is open
    document.body.style.overflow = 'hidden';

    // Pass lockModal to prevent the modal from being closed by clicking outside of it
    if (e?.currentTarget?.dataset?.lockModal === undefined && options.lockModal === undefined) {
      this.modalTarget.addEventListener('mousedown', this.boundDialogClick);
      document.addEventListener('keydown', this.boundEscapePress);
    }

    // Remove hidden classes
    this.spinnerTarget.classList.remove('hidden');
    this.modalTarget.classList.remove('hidden');
    this.dialogTarget.classList.remove('hidden');

    // Set initial opacity to 0
    gsap.set(this.dialogTarget, { opacity: 0 });

    // Animate the dialog background
    gsap.to(this.dialogTarget, {
      opacity: 1,
      duration: 0.3,
      ease: 'power1.out' // This is GSAP's version of ease-out
    });
  }

  /**
   * @param {MouseEvent} e
   */
  closeModal(e) {
    this.modalTarget.removeEventListener('mousedown', this.boundDialogClick);
    document.removeEventListener('click', this.boundDialogClick);

    const isMobile = window.matchMedia('(max-width: 640px)').matches;

    // Animate panel first if it exists
    if (this.hasPanelTarget) {
      gsap.to(this.panelTarget, {
        opacity: 0,
        y: isMobile ? 16 : 0, // 4px on mobile, 0 on desktop
        scale: isMobile ? 1 : 0.95, // Only scale on desktop
        duration: 0.2,
        ease: 'power1.in' // Similar to ease-in
      });
    }

    // Animate dialog background
    gsap.to(this.dialogTarget, {
      opacity: 0,
      duration: 0.3,
      ease: 'power1.out',
      onComplete: () => {
        this.modalTarget.classList.add('hidden');
        this.dialogTarget.classList.add('hidden');
        this.spinnerTarget.classList.add('hidden');
        document.body.style.overflow = '';
        document.getElementById('trade_modal_panel').innerHTML = '';

        if (this.lastActiveElement) {
          this.lastActiveElement.focus();
        }
      }
    });
  }
}
